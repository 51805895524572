<template>
  <template v-if="$vuetify.display.mobile">
    <v-row>
      <v-col>
        <small>Nome</small>
      </v-col>
      <v-col class="text-right">
        <small>Custo / Preço</small>
      </v-col>
    </v-row>
    <v-divider class="my-4" />
    <template v-for="(productVariant, index) of variants">
      <v-row @click="handleEdit(index)">
        <v-col>
          {{ productVariant.name }}
          <AppStatusChip
            v-if="!productVariant.status"
            :value="productVariant.status"
            size="small"
          />
          <template v-if="showNewLabel(productVariant)">
            <small class="text-primary">(Novo)</small>
          </template>
          <template v-if="showStock">
            <br />
            <small class="text-medium-emphasis"
              >Estoque:
              <ProductQuantityLabel
                :product="product"
                :quantity="getStock(productVariant)"
            /></small>
          </template>
        </v-col>
        <v-col class="text-right">
          Preço: {{ $format.decimal(productVariant.price) }}
          <br />
          <small class="text-medium-emphasis">
            Custo: {{ $format.decimal(productVariant.cost) }}</small
          >
        </v-col>
      </v-row>
      <v-divider class="my-4" />
    </template>
    <div class="text-end mb-4">
      <AppBtn
        @click="showInactiveVariants()"
        variant="text"
        size="small"
        :prepend-icon="
          showInactive ? 'mdi:mdi-chevron-up' : 'mdi:mdi-chevron-down'
        "
      >
        Exibir inativos
      </AppBtn>
    </div>
  </template>

  <template v-else>
    <v-row>
      <v-col>
        <small>Nome</small>
      </v-col>
      <v-col class="text-right">
        <small>Custo</small>
      </v-col>
      <v-col class="text-right">
        <small>Preço</small>
      </v-col>
      <v-col class="text-right" v-if="showStock">
        <small>Estoque Min.</small>
      </v-col>
      <v-col class="text-right" v-if="showStock">
        <small>Estoque</small>
      </v-col>
    </v-row>
    <v-divider class="my-4" />
    <template v-for="(productVariant, index) of sortedVariants">
      <v-row
        @click="handleEdit(index)"
        :class="getDisabledClass(productVariant)"
      >
        <v-col>
          {{ productVariant.name }}
          <AppStatusChip
            v-if="!productVariant.status"
            :value="productVariant.status"
            size="small"
          />
          <template v-if="showNewLabel(productVariant)">
            <small class="text-primary">(Novo)</small>
          </template>
        </v-col>
        <v-col class="text-right">
          {{ $format.decimal(productVariant.cost) }}
        </v-col>
        <v-col class="text-right">
          {{ $format.decimal(productVariant.price) }}
        </v-col>
        <v-col class="text-right" v-if="showStock">
          <template v-if="productVariant.stock_min">
            {{ $format.decimal(productVariant.stock_min, 0) }}
          </template>
          <template v-else> - </template>
        </v-col>
        <v-col class="text-right" v-if="showStock">
          <ProductQuantityLabel
            :class="[stockLabelColor(productVariant)]"
            :product="product"
            :quantity="getStock(productVariant)"
          />
        </v-col>
      </v-row>
    </template>
    <v-divider class="my-4" />
    <div class="text-end">
      <AppBtn
        @click="showInactiveVariants()"
        variant="text"
        size="small"
        :prepend-icon="
          showInactive ? 'mdi:mdi-chevron-up' : 'mdi:mdi-chevron-down'
        "
      >
        Exibir inativos
      </AppBtn>
    </div>
  </template>
</template>

<script setup>
const productForm = useProductFormStore();

const { variants, showInactive } = storeToRefs(productForm);

const prop = defineProps({
  product: {
    required: true,
  },
});

const emit = defineEmits(["edit"]);

const handleEdit = (index) => {
  emit("edit", index);
};

const sortedVariants = computed(() => {
  return variants.value.sort((a, b) => {
    if (a.name === null || b.name === null) {
      return 0;
    }
    return a.name.localeCompare(b.name);
  });
});

const productVariants = computed(() => {
  return prop.product.product_variants;
});

const showStock = computed(() => {
  return prop.product.control_stock;
});

const isInsert = computed(() => {
  return !prop.product.id;
});

const isNew = (productVariant) => {
  return !productVariant.id;
};

const showNewLabel = (productVariant) => {
  return isNew(productVariant) && !isInsert.value;
};

const getStock = (productVariant) => {
  if (isNew(productVariant)) {
    return productVariant.initial_stock;
  }

  return productVariant.stock;
};

const stockLabelColor = (productVariant) => {
  if (Number(productVariant.stock) <= 0) {
    return "text-error";
  }

  if (Number(productVariant.stock) <= Number(productVariant.stock_min)) {
    return "text-warning";
  }

  return null;
};

const showInactiveVariants = () => {
  showInactive.value = !showInactive.value;

  if (showInactive.value) {
    productVariants.value.sort((a, b) =>
      a.status === b.status ? 0 : a.status ? -1 : 1
    );
  }
};

const getDisabledClass = (productVariant) => {
  if (!productVariant.status) {
    return "text-medium-emphasis";
  }

  return "";
};
</script>

<style lang="scss" scoped></style>